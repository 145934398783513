<template>
  <div>
    <el-form
      ref="form"
      :key="key"
      :model="parameter"
      :inline="true"
      @submit.prevent.native="null"
      autocomplete="off"
      class="template-parameters-group__item"
    >
      <base-input
        v-if="!this.groupType || this.groupType !== 'list'"
        class="key"
        ref="key"
        prop="key"
        placeholder="name"
        v-model.trim="parameter.key"
        name="key"
        auto-complete="off"
        :max-width="200"
      ></base-input>
      <base-select
        :value="parameter.type"
        ref="type"
        prop="type"
        :items="types"
        name="type"
        :max-width="120"
        @input="changeType"
      ></base-select>
      <template v-if="parameter.isType(['text', 'number'])">
        <base-input
          class="value"
          ref="value"
          :type="parameter.type"
          prop="value"
          v-model.trim="parameter.value"
          name="value"
          placeholder="value"
          auto-complete="off"
          :max-width="200"
        ></base-input>
      </template>
      <template v-else-if="parameter.isType(['boolean'])">
        <base-select
          v-model="parameter.value"
          ref="value"
          prop="value"
          :items="booleanList"
          name="value"
          :max-width="200"
        ></base-select>
      </template>
      <base-button
        @click="handleDelete"
        prepend-icon="ri-delete-bin-2-line"
        :round="false"
        circle
      ></base-button>
    </el-form>
    <template v-if="parameter.isType(['list', 'object'])">
      <parameters-group
        v-model="parameter.value"
        :group-type="parameter.type"
        :offset="offset + 1"
      ></parameters-group>
    </template>
  </div>
</template>

<script>

import TemplateParameter from '@/app/classes/TemplateParameter'

export default {
  name: 'ParametersGroupItem',
  components: {
    ParametersGroup: () => import('@/components/Templates/ParametersGroup')
  },
  props: {
    value: {
      type: Object,
      default: () => {
      }
    },
    groupType: {
      type: String,
      default: null
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      key: null
    }
  },
  computed: {
    parameter: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    types () {
      return TemplateParameter.types.map(type => ({
        label: type,
        value: type
      }))
    },
    booleanList () {
      return [
        {
          value: true,
          label: 'True'
        },
        {
          value: false,
          label: 'False'
        }
      ]
    }
  },
  methods: {
    changeType (type) {
      console.log(type)
      this.parameter.setType(type)
      this.key = this.$key()
    },
    handleDelete () {
      this.$emit('delete')
    }
  }
}
</script>
